{#if $apiConfig.storageEndpoint !== ''}
<LibLoader url={bannerConfigJS} on:loaded="{onLoaded}" />
{/if}
<script>
    import { layoutOption, apiConfig, mode } from '../store/inapp-store' 
    import { openWebview } from '../store/openpoint'
    import { Swiper, SwiperSlide } from 'swiper/svelte'
    import { Navigation, Pagination } from 'swiper'
    import 'swiper/css'
    import 'swiper/css/navigation'
    import 'swiper/css/pagination'
    import { LibLoader } from '../components';

    let bannerConfigJS = ''
    let banners= []
    let isOpen = false
    let fullBannerUrl = ''

    $:{
        //-- 取得輪播圖片設定JS所在位置
        bannerConfigJS = `${$apiConfig.storageEndpoint}/asset-container/banners/banner-config.js?d=${new Date().getTime()}`  
    }

    /**
     * 載入輪播圖片設定後會觸發此事件
     */ 
    function onLoaded() {
        // console.log('### bannerConfig=', bannerConfig)
        var arr = []
        bannerConfig.bannerList.forEach(banner => {
            let beginTime= banner.beginTime.replace(/\-/g, "/").replace(/\T/g, " ");
            let endTime = banner.endTime.replace(/\-/g, "/").replace(/\T/g, " ");
            if (new Date(beginTime) < new Date() && new Date(endTime) > new Date()) {
                arr.push(banner)
            } 
        });

        banners = arr
    }

    // $:console.log('banners', banners)

    function redirect(url) {
        if(url !== '') { location.href = url }
    }
    
    function openBrowser(url) {
        if(url === '') { return false }

        if ($mode === 'app') {
            openWebview(false, url)
            location.href = $layoutOption.navBackUrl 
        } else {
            location.href = url 
        }
    }

    function openModal(url) {
        isOpen = true
        fullBannerUrl = url
    }

    function close() {
        isOpen = false
    }

    /**
   * 開啟外部連結-Banner URL
   */
  function redirectBannerUrl() {
    if ($mode === 'app') {
      openWebview(false, fullBannerUrl)
      location.href = $layoutOption.navBackUrl 
    } else {
      location.href = fullBannerUrl
    }
  }

</script>
<style>
img.banner {
  display: block;
  max-width: 100%;
  min-width: 375px;
  height: 220px;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}

img.full-banner {
  display: block;
  max-width: 100%;
  min-width: 375px;
  -o-object-fit: cover;
  object-fit: cover;
  overflow: hidden;
}

</style>

<div style="display:{$layoutOption.showCarousel ? 'block': 'none'}">
    <Swiper pagination={true} modules={[Pagination]} class="swiper-container mySwiper fp-container" >
        {#each banners as banner(banner)}
            <SwiperSlide class="fp-slide">
                {#if banner.fullImgUrl && banner.fullImgUrl !== ''}
                    <img class="banner" src="{banner.imgUrl}" alt="" on:click={()=> openModal(banner.fullImgUrl) }>
                {:else if banner.openUrl && banner.openUrl !== ''}
                    <img class="banner" src="{banner.imgUrl}" alt="" on:click={()=> openBrowser(banner.openUrl) }>
                {:else if banner.url !== ''}
                    <img class="banner" src="{banner.imgUrl}" alt="" on:click={()=> redirect(banner.url) }>
                {:else}
                    <img class="banner" src="{banner.imgUrl}" alt="" >
                {/if}
            </SwiperSlide> 
        {/each}
    </Swiper>
</div>

<div id="mySendModal" class="send-modal" style="display:{isOpen ? 'block': 'none'}" on:click={close}>
    <div class="box">
        <div class="send-modal-ctn-wrap" style="height: 12.125 rem;">
            <div class="send-modal-ctn-nr me-2">
                <span class="send-modal-ctn-nr-p pt-3 px-3">
                    <img class="full-banner" src="{fullBannerUrl}" alt="" on:click={redirectBannerUrl}>
                </span>
            </div>
        </div>
    </div>
</div>